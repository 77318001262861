import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { FormControl, InputLabel, MenuItem, Select, Typography, TextField, Divider } from '@material-ui/core';

const useStyles = makeStyles({
  table: {
    minWidth: 1000,
    position: 'relative',
    top: -11
  },
  container: {
    maxHeight: 1000,
    padding: '0 0 10px',
    overflow: 'visible',
    marginTop: 122
  },
  stickyHeader: {
    backgroundColor: '#e8ebf7',
    position: "sticky",
    top: 112,
    zIndex: 100,
    boxShadow: '1px 1px 1px #ccc'
  },
  formControl: {
    width: 140,
    marginLeft: 10
  },
  adminOrdersSumStyle: {
    width: 250,
    marginLeft: 10,
    position: "relative",
    top: 17
  },
  filterRow: {
    backgroundColor: '#e8ebf7',
    width: '100%',
    display: 'inline-block',
    position: 'fixed',
    top: 64,
    zIndex: 101,
    borderBottom: '1px solid #ccc'
  },
});

const AdminViewTableOrderList = (props) => {
  const { items, month, year, managerId, managerList, location, setLocation, adminData, adminOrdersSum, source, handleChangeMonth, handleChangeYear, handleChangeManager, handleChangeSource } = props
  let jItems = items.filter(item => item.orderId.startsWith("j-"));
  console.log("🚀 ~ {items.map ~ jItems:", jItems.map(item => item.orderId))
  const jItemsLenght = jItems.length
  console.log("🚀 ~ {items.map ~ jItemsLenght:", jItemsLenght)
  const handleChangeLocation = (event) => {
    setLocation(event.target.value);
  }

  const classes = useStyles();
  return (
    <>
      <div className={classes.filterRow}>
        <FormControl className={classes.formControl}>
          <InputLabel id="month label">Месяц</InputLabel>
          <Select
            value={month}
            onChange={handleChangeMonth}
          >
            <MenuItem value={12}>12</MenuItem>
            <MenuItem value={11}>11</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={9}>9</MenuItem>
            <MenuItem value={8}>8</MenuItem>
            <MenuItem value={7}>7</MenuItem>
            <MenuItem value={6}>6</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={1}>1</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="year label">Год</InputLabel>
          <Select
            value={year}
            onChange={handleChangeYear}
          >
            <MenuItem value={2024}>2024</MenuItem>
            <MenuItem value={2023}>2023</MenuItem>
            <MenuItem value={2022}>2022</MenuItem>
            <MenuItem value={2021}>2021</MenuItem>
          </Select>
        </FormControl>
        {(adminData.role === 'admin' || adminData.role === 'localAdmin') && <FormControl className={classes.formControl}>
          <InputLabel id="year label">Источник</InputLabel>
          <Select
            value={source}
            onChange={handleChangeSource}
          >
            <MenuItem value={'all'}>Все</MenuItem>
            <MenuItem value={'shop-promocode'}>Shop-promo</MenuItem>
            <MenuItem value={'shop-no-promocode'}>Shop-no_promo</MenuItem>
            <MenuItem value={'Косметолог'}>Shop-косметологи</MenuItem>
            <MenuItem value={'Аптека'}>Аптеки</MenuItem>
            <MenuItem value={'Клиника'}>Клиники</MenuItem>
            <MenuItem value={'Дистрибьютор'}>Дистрибьюторы</MenuItem>
            <MenuItem value={'ozonFBO'}>Ozon FBO</MenuItem>
            <MenuItem value={'ozonFBS'}>Ozon FBS</MenuItem>
            <MenuItem value={'wb'}>WB</MenuItem>
            <MenuItem value={'yandexFBY'}>yandex</MenuItem>
          </Select>
        </FormControl>
        }
        <FormControl className={classes.formControl}>
          <InputLabel id="manager label">Менеджер</InputLabel>
          <Select
            value={managerId}
            onChange={handleChangeManager}
          >
            {managerList.map(item => {
              return <MenuItem value={item.id}>{item.managerName}</MenuItem>
            })}
          </Select>
        </FormControl>

        <TextField
          id="standard-disabled"
          value={`Общая сумма: ${adminOrdersSum}`}
          color="secondary"
          className={classes.adminOrdersSumStyle}
        />
      </div>
      <TableContainer component={Paper} className={classes.container} elevation={0}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead className={classes.stickyHeader}>
            <TableRow>
              <TableCell>Номер заказа</TableCell>
              <TableCell>Артикль</TableCell>
              <TableCell>Название препарата</TableCell>
              <TableCell>К-во</TableCell>
              <TableCell>Сумма</TableCell>
              <TableCell>Город</TableCell>
              <TableCell>Регион</TableCell>
              <TableCell>Страна</TableCell>
              <TableCell>Менеджер</TableCell>
              <TableCell>Промокод</TableCell>
              <TableCell>Номер телефона</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Имя покупателя</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.id}>
                {/* <TableCell component="th" scope="row">
                {id].managerId.managerName}
              </TableCell> */}
                <TableCell component="th" scope="row">
                  {/* {id].orderId} */}
                  <Typography variant="body2"
                    style={{
                      maxWidth: 50,
                      whiteSpace: 'pre-wrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                    title={item.orderId}
                  >
                    {item.orderId}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography
                    variant="body2"
                    style={{
                      maxWidth: 50,
                      whiteSpace: 'pre-wrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    title={item.productArticle}
                  >
                    {item.productArticle}
                  </Typography>
                </TableCell>
                {item.orderId.includes('-n') ?
                  <TableCell component="th" scope="row">
                    <Typography
                      variant="body2"
                      style={{
                        maxWidth: 300,
                        whiteSpace: 'pre-wrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}>
                      {item.productTitle}
                    </Typography>
                  </TableCell> :
                  <TableCell component="th" scope="row">
                    {Object.values(item.products).map((product, index) => (
                      <>
                        <Typography
                          variant="body2"
                          style={{
                            maxWidth: 300,
                            whiteSpace: 'pre-wrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}>
                          {product.title}. - {product.quantity} шт.
                        </Typography>
                        {Object.keys(item.products).length - 1 !== index && <Divider />}
                      </>
                    ))}
                  </TableCell>}
                <TableCell component="th" scope="row">
                  <Typography
                    variant="body2"
                    style={{
                      maxWidth: 300,
                      whiteSpace: 'pre-wrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}>
                    {item.productQuantity}
                  </Typography>
                </TableCell>
                {item.orderId.includes('-n') ? <TableCell component="th" scope="row">
                  {item.productTotalPrice}
                </TableCell> :
                  <TableCell component="th" scope="row">
                    {item.totalPrice}
                  </TableCell>}
                <TableCell component="th" scope="row">
                  <Typography
                    variant="body2"
                    style={{
                      maxWidth: 100,
                      whiteSpace: 'pre-wrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}>
                    {item.city}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography
                    variant="body2"
                    style={{
                      maxWidth: 100,
                      whiteSpace: 'pre-wrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}>
                    {item.region}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography
                    variant="body2"
                    style={{
                      maxWidth: 100,
                      whiteSpace: 'pre-wrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}>
                    {item.country}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography
                    variant="body2"
                    style={{
                      maxWidth: 100,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}>
                    {item.manager ? item.manager.managerName : 'empty'}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography
                    variant="body2"
                    style={{
                      whiteSpace: 'pre-wrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}>
                    {item.promocode === 'no orderPromocode' ? "-" : item.promocode}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography
                    variant="body2"
                    style={{
                      maxWidth: 200,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}>
                    {item.clientPhone}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography
                    variant="body2"
                    style={{
                      maxWidth: 200,
                      whiteSpace: 'pre-wrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}>
                    {item.clientEmail}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  {item.clientName}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default AdminViewTableOrderList