import { createSelector } from 'reselect'
import { userDataSelector } from './userDataSelector'
import { map } from 'lodash';
import { finLimitValue } from '../constants/data';

export const managerListSelector = (state) => state.managerList.managerList

export const managerSalesSelector = (state) => state.managerList.sales

export const allManagerListSelector = createSelector(
    [managerListSelector],
    (items) => {
        const result = [];
        const map = new Map();
        for (const item of items) {
            if (!map.has(item.id) && (item.manager_status === "main" || item.manager_status === "other")) {
                map.set(item.id, true);
                result.push({
                    id: item.id,
                    managerName: item.managerName
                });
            }
        }
        const allManagers = { id: 0, managerName: 'Все менеджеры' }
        result.unshift(allManagers)
        return result
    }
)


export const managerSalesTableSelector = createSelector(
    [managerSalesSelector],
    (managerSales) => {

        const res = managerSales.sort((a, b) => a.month > b.month)
        return res
    }
)

export const managerSalesFinLimitSelector = createSelector(
    [managerSalesTableSelector, userDataSelector],
    (managerSalesTable, userData) => {
        const { monthData, yearData } = userData.settingsData
        const managerLocale = userData.managerLocale
        if (managerSalesTable && monthData) {
            const filterList = (item) => {
                if (item.month === monthData) {
                    return item
                }
            }

            const res = managerSalesTable.find(filterList)

            const finLimit = res ? Math.round((finLimitValue / 100) * (res.otherSale + res.aptekSale + res.marketsSale)) : 0
            // const finLimitRU = res ? res.otherSale + res.aptekSale : 0

            return finLimit
        }
        return
    }
)

export const adminManagerSalesFinLimitSelector = (month, year, managerId) => createSelector(
    [managerSalesTableSelector, userDataSelector],
    (managerSalesTable, userData) => {
        if (managerSalesTable && month) {
            console.log("🚀 ~ file: managerListSelector.js ~ line 66 ~ managerSalesTable", managerSalesTable)
            const filterList = (item) => {
                if (item.month === month) {
                    return item
                }
            }

            const res = managerSalesTable.find(filterList)

            const finLimit = res ? Math.round((finLimitValue / 100) * (res.otherSale + res.aptekSale + res.marketsSale)) : 0
            // const finLimitRU = res ? res.otherSale + res.aptekSale : 0

            return finLimit
        }
        return
    }
)

export const managerListSalesTotalSumSelector = (year) => createSelector(
    [managerListSelector, managerSalesTableSelector],
    (managerList, managerSalesTable) => {
        const resManagersSales = [];

        Object.values(managerList).sort((a, b) => Number(a.sortNumber) - Number(b.sortNumber)).forEach((item) => {
            const managerId = Number(item.id);
            const managerName = item.managerName;
            const managerBonusPercent = item.bonusPercent;

            const managerSales = managerSalesTable.filter(sale => sale.manager && sale.manager.id === managerId);

            const monthlySales = managerSales.reduce((acc, curr) => {
                if (curr.year === year) {
                    const month = curr.month;
                    const totalSaleSum = curr.saleSum + curr.otherSale + curr.aptekSale + curr.clinicSale + curr.cosmetologSale + curr.marketsSale + curr.distributorSale
                    const managerBonusSum = Math.round((managerBonusPercent / 100) * totalSaleSum);
                    const managerProfit = managerBonusSum + curr.extraSum + curr.minOklad

                    const monthlyData = {
                        id: curr.id,
                        saleSum: curr.saleSum,
                        otherSale: curr.otherSale,
                        aptekSale: curr.aptekSale,
                        clinicSale: curr.clinicSale,
                        cosmetologSale: curr.cosmetologSale,
                        marketsSale: curr.marketsSale,
                        distributorSale: curr.distributorSale,
                        bonusSum: curr.bonusSum,
                        minOklad: curr.minOklad || 0,
                        managerProfit: managerProfit,
                        totalSaleSum: totalSaleSum,
                        managerBonusSum: managerBonusSum
                    };

                    acc[month - 1] = monthlyData;
                }
                return acc;
            }, []);

            const managerItemSales = { managerId, managerName, monthlySales };
            resManagersSales.push(managerItemSales);
        });
        return resManagersSales;
    }
);

export const managerItemSalesTotalSumSelector = createSelector(
    [managerSalesTableSelector, userDataSelector],
    (managerSalesTable, userData) => {
        const resCalcSales = []

        // const managerId = userData.managerId
        // const managerName = userData.managerName
        const managerBonusPercent = userData.managerBonusPercent
        // const salesValues = Object.values(managerSalesTable)
        managerSalesTable.forEach((item) => {
            const totalSaleSum = item.saleSum + item.otherSale + item.aptekSale + item.clinicSale + item.distributorSale + item.cosmetologSale + item.marketsSale
            const managerBonusSum = Math.round((managerBonusPercent / 100) * totalSaleSum)
            const managerProfit = managerBonusSum + item.extraSum + item.minOklad
            const calcSales = { ...item, totalSaleSum: totalSaleSum, managerBonusSum: managerBonusSum, managerProfit: managerProfit }
            resCalcSales.push(calcSales)
        })

        const sortedResCalcSales = resCalcSales.sort((a, b) => a.month - b.month)

        return sortedResCalcSales
    }
)