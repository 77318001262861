import axios from 'axios'

import promocodesList from './promocodesList.json' // edited 181121
import newPromocodesList from './newPromocodesList.json' // edited 0961221
import promocodesUpdateList from './promocodesUpdateList.json' // edited 161121
import promocodesAddRuList from './promocodesAddRuList.json' // edited 171121
import cityList from '../old/cityListData.json'
import { BACKEND_DOMAIN } from './../data/constants/statuses';

export const addPromocodeApi = (userData) => {
    // правки в промокоды вносим через файл json!!!
    const { token } = userData
    // console.log("🚀 ~ file: promocodesApi.js ~ line 4 ~ promocodesList", promocodesList)

    Object.values(newPromocodesList).forEach((item, index) => {

        // const dataPromocode = {
        //     manager: item.manager,
        //     promocode: item.promocode.toLowerCase(),
        //     doctor: item.doctor,
        //     clinic: item.clinic,
        //     city: item.city,
        //     region: item.region,
        //     phone: item.phone.toString(),
        //     cardNumber: item.cardNumber.toString(),
        //     locale: "uk-UA",
        //     doctorBonusPercent: 15
        // }
        // console.log("🚀 ~ file: promocodesApi.js ~ line 29 ~ Object.values ~ dataPromocode", dataPromocode)
        const dataNewPromocode = {
            manager: 15,
            promocode: item.promocode,
            doctor: '',
            clinic: '',
            city: '',
            region: '',
            phone: '',
            cardNumber: '',
            locale: "uk-UA",
            doctorBonusPercent: 15
        }
        console.log("🚀 ~ file: promocodesApi.js ~ line 42 ~ Object.values ~ dataNewPromocode", dataNewPromocode)

        // axios
        //     .post(`${BACKEND_DOMAIN}/promocodes`, dataNewPromocode,
        //         {
        //             headers: {
        //                 Authorization: `Bearer ${token}`
        //             }
        //         }).then(response => {
        //             console.log(response);
        //         });
    })
}
export const manualUpdatePromocodeApi = (userData, promocodes, location) => {
    // правки в промокоды вносим через файл json!!!
    const { token } = userData
    // console.log("🚀 ~ file: promocodesApi.js ~ line 4 ~ promocodesList", promocodesList)

    Object.values(promocodesUpdateList).forEach((item, index) => {
        const promocodeIndex = promocodes.findIndex(element => element.promocode.toLowerCase() == item.promocode.toLowerCase());
        const promocodeData = promocodeIndex !== -1 ? promocodes[promocodeIndex] : ''
        // console.log("🚀 ~ file: promocodesApi.js ~ line 60 ~ Object.values ~ promocodeData", promocodeData)
        const promocodeId = promocodeData.id
        // if (promocodeId === undefined) {
        //     console.log("🚀 ~ file: promocodesApi.js ~ line 61 ~ Object.values ~ promocodeId", promocodeId, item.promocode)
        // }
        if (promocodeIndex > 0 && promocodeId && promocodeData.doctor == null) {
            console.log("🚀 ~ file: promocodesApi.js ~ line 65 ~ Object.values ~ item", item)
            const dataPromocode = {
                manager: 48,
                // promocode: item.promocode.toLowerCase(),
                doctor: item.doctor,
                // clinic: item.clinic,
                city: 'Москва',
                region: 'Московская область',
                phone: item.phone.toString(),
                // cardNumber: item.cardNumber.toString(),
                locale: location,
                doctorBonusPercent: 15
            }
            // console.log("🚀 ~ file: promocodesApi.js ~ line 76 ~ Object.values ~ dataPromocode", dataPromocode)

            // axios
            //     .put(`${BACKEND_DOMAIN}/promocodes/${promocodeId}`, dataPromocode,
            //         {
            //             headers: {
            //                 Authorization: `Bearer ${token}`
            //             }
            //         }).then(response => {
            //             console.log(response);
            //         });
        }
    })
}

export const manualAddPromocodeRuApi = (userData) => {
    // правки в промокоды вносим через файл json!!!
    const { token } = userData

    Object.values(promocodesAddRuList).forEach((item, index) => {
        const dataPromocode = {
            manager: item.manager,
            promocode: item.promocode.toLowerCase(),
            doctor: item.doctor,
            clinic: item.clinic,
            city: item.city,
            region: item.region,
            phone: item.phone.toString(),
            cardNumber: item.cardNumber.toString(),
            locale: 'ru-RU',
            doctorBonusPercent: 15
        }
        console.log("🚀 ~ file: promocodesApi.js ~ line 76 ~ Object.values ~ dataPromocode", dataPromocode)

        // axios
        //     .post(`${BACKEND_DOMAIN}/promocodes`, dataPromocode,
        //         {
        //             headers: {
        //                 Authorization: `Bearer ${token}`
        //             }
        //         }).then(response => {
        //             console.log(response);
        //         });
    })
}

export const addCitiesApi = (userData) => {

    const { token } = userData


    cityList.forEach((item, index) => {

        const dataCity = {
            city: Object.keys(item)[0],
            region: Object.values(item)[0],
            locale: "uk-UA"
        }

        axios
            .post(`${BACKEND_DOMAIN}/cities`, dataCity,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }).then(response => {
                    console.log(response);
                });
    })
}

export const getManagerPromocodeListApi = async (userData) => {
    const { token, managerId, managerLocale } = userData
    const res = axios
        .get(`${BACKEND_DOMAIN}/promocodes?_limit=-1&_locale=${managerLocale}&manager=${managerId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                return response.data
            });
    return res;
}

export const getAllManagersPromocodeListApi = async (userData, managerId, location) => {
    const { token } = userData
    const allManagers = managerId === 0 ? '' : `&manager=${managerId}`
    const res = axios
        .get(`${BACKEND_DOMAIN}/promocodes?_limit=-1&_locale=${location}${allManagers}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                return response.data
            });
    return res;
}

export const updatePromocodeByIdApi = async (id, data, userData) => {
    const { token } = userData
    const res = axios
        .put(`${BACKEND_DOMAIN}/promocodes/${id}`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            return response.status
        });
    return res;
}

export const getManagerPromocodeSumListApi = async (userData) => { // temporarily used
    const { token, managerId, managerLocale, settingsData } = userData

    const res = axios
        .get(`${BACKEND_DOMAIN}/promocodes-bonuses/calcBonuses`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                return response.data
            });
    return res;
}

export const getManagerPromocodeBonusListApi = async (userData, filterMonth, filterYear) => {
    const { token, managerId, managerLocale, settingsData } = userData

    const month = filterMonth ? filterMonth : settingsData.monthData
    const year = filterYear ? filterYear : settingsData.yearData

    // const res2 = await getManagerPromocodeSumListApi(userData) //temporarily used
    const res = axios
        .get(`${BACKEND_DOMAIN}/promocodes-bonuses?_limit=-1&_locale=${managerLocale}&manager=${managerId}&bonusMonth=${month}&bonusYear=${year}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                return response.data
            });
    return res;
}

export const getAllManagerPromocodeBonusListApi = async (userData, year, month, managerId, location) => {
    const { token } = userData
    const allManagers = managerId === 0 ? '' : `&manager=${managerId}`
    const res = axios
        .get(`${BACKEND_DOMAIN}/promocodes-bonuses?_limit=-1&_locale=${location}${allManagers}&bonusMonth=${month}&bonusYear=${year}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                return response.data
            });
    return res;
}

export const updatePromocodeBonusByIdApi = async (id, data, userData) => {
    const { token } = userData
    const res = axios
        .put(`${BACKEND_DOMAIN}/promocodes-bonuses/${id}`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            return response.status
        });
    return res;
}