const mytext =

`
1CClientBankExchange
ВерсияФормата=1.03
Кодировка=Windows
Отправитель=СБИС
ДатаСоздания=11.09.2024
ВремяСоздания=09:27:45
ДатаНачала=11.09.2024
ДатаКонца=11.09.2024
РасчСчет=40702810538000147270
СекцияДокумент=Платежное поручение
Номер=1654
Дата=02.08.2024
Сумма=73746.90
ПлательщикСчет=40702810162000024866
Плательщик=ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "МЕДИЦИНА КРАСОТЫ"
ПлательщикИНН=1655089121
Плательщик1=ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "МЕДИЦИНА КРАСОТЫ"
ПлательщикРасчСчет=40702810162000024866
ПлательщикБанк1=ОТДЕЛЕНИЕ "БАНК ТАТАРСТАН" N8610 ПАО СБЕРБАНК
ПлательщикБанк2=КАЗАНЬ
ПлательщикБИК=049205603
ПлательщикКорсчет=30101810600000000603
ПолучательСчет=40702810538000147270
ДатаПоступило=02.08.2024
Получатель=Общество с ограниченной ответственностью "ПЕРОЛАЙТ"
ПолучательИНН=7716859156
Получатель1=Общество с ограниченной ответственностью "ПЕРОЛАЙТ"
ПолучательРасчСчет=40702810538000147270
ПолучательБанк1=ПАО Сбербанк
ПолучательБанк2=МОСКВА
ПолучательБИК=044525225
ПолучательКорсчет=30101810400000000225
КодНазПлатежа=
ВидОплаты=01
НазначениеПлатежа=Оплата по договору 2414/1 от 23.05.2023.  и по акту сверки на 02.08.2024 за косметологический товар Сумма 73746-90 В т.ч. НДС  (20%) 12291-15
НазначениеПлатежа1=Оплата по договору 2414/1 от 23.05.2023.  и по акту сверки на 02.08.2024 за косметологический товар Сумма 73746-90 В т.ч. НДС  (20%) 12291-15
ПлательщикКПП=165501001
ПолучательКПП=772101001
Очередность=5
КонецДокумента
СекцияДокумент=Платежное поручение
Номер=734
Дата=06.08.2024
Сумма=26687.00
ПлательщикСчет=40802810402920006687
Плательщик=ИП Захарова Светлана Петровна
ПлательщикИНН=711801172591
Плательщик1=ИП Захарова Светлана Петровна
ПлательщикРасчСчет=40802810402920006687
ПлательщикБанк1=АО "АЛЬФА-БАНК"
ПлательщикБанк2=МОСКВА
ПлательщикБИК=044525593
ПлательщикКорсчет=30101810200000000593
ПолучательСчет=40702810538000147270
ДатаПоступило=06.08.2024
Получатель=Общество с ограниченной ответственностью "ПЕРОЛАЙТ"
ПолучательИНН=7716859156
Получатель1=Общество с ограниченной ответственностью "ПЕРОЛАЙТ"
ПолучательРасчСчет=40702810538000147270
ПолучательБанк1=ПАО Сбербанк
ПолучательБанк2=МОСКВА
ПолучательБИК=044525225
ПолучательКорсчет=30101810400000000225
КодНазПлатежа=
ВидОплаты=01
НазначениеПлатежа=ОПЛАТА ЗА ТОВАР ПО ДОГОВОРУ С ПОСТАВЩИКОМ № 2434 ОТ 07.07.2023Г. СОГЛАСНО СЧЕТА № 7219 ОТ 02.08.2024Г. СУММА 26687-00 В Т.Ч. НДС (20%) 4447-83
НазначениеПлатежа1=ОПЛАТА ЗА ТОВАР ПО ДОГОВОРУ С ПОСТАВЩИКОМ № 2434 ОТ 07.07.2023Г. СОГЛАСНО СЧЕТА № 7219 ОТ 02.08.2024Г. СУММА 26687-00 В Т.Ч. НДС (20%) 4447-83
ПолучательКПП=772101001
Очередность=5
КонецДокумента
СекцияДокумент=Платежное поручение
Номер=948
Дата=06.08.2024
Сумма=9025.00
ПлательщикСчет=40702810187760000581
Плательщик=ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ЗДРАВИЯ"
ПлательщикИНН=7104070728
Плательщик1=ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ЗДРАВИЯ"
ПлательщикРасчСчет=40702810187760000581
ПлательщикБанк1=ПАО РОСБАНК
ПлательщикБанк2=Москва
ПлательщикБИК=044525256
ПлательщикКорсчет=30101810000000000256
ПолучательСчет=40702810538000147270
ДатаПоступило=06.08.2024
Получатель=Общество с ограниченной ответственностью "ПЕРОЛАЙТ"
ПолучательИНН=7716859156
Получатель1=Общество с ограниченной ответственностью "ПЕРОЛАЙТ"
ПолучательРасчСчет=40702810538000147270
ПолучательБанк1=ПАО Сбербанк
ПолучательБанк2=МОСКВА
ПолучательБИК=044525225
ПолучательКорсчет=30101810400000000225
КодНазПлатежа=
ВидОплаты=01
Код=0
НазначениеПлатежа=Оплата за товар по договору с поставщиком № 2438 от 07.02.2024г. согласно счета № 7220 от 02.08.2024г. Сумма 9025-00 В т.ч. НДС (20%) 1504-17
НазначениеПлатежа1=Оплата за товар по договору с поставщиком № 2438 от 07.02.2024г. согласно счета № 7220 от 02.08.2024г. Сумма 9025-00 В т.ч. НДС (20%) 1504-17
ПлательщикКПП=710301001
ПолучательКПП=772101001
Очередность=5
КонецДокумента
СекцияДокумент=Платежное поручение
Номер=2232
Дата=15.08.2024
Сумма=5396.00
ПлательщикСчет=
Плательщик=ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ГРАНД МЕДИКАЛ"
ПлательщикИНН=9102158674
Плательщик1=ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ГРАНД МЕДИКАЛ"
ПлательщикРасчСчет=
ПлательщикБанк1=
ПлательщикБанк2=
ПлательщикБИК=
ПлательщикКорсчет=
ПолучательСчет=40702810538000147270
ДатаПоступило=15.08.2024
Получатель=Общество с ограниченной ответственностью "ПЕРОЛАЙТ"
ПолучательИНН=7716859156
Получатель1=Общество с ограниченной ответственностью "ПЕРОЛАЙТ"
ПолучательРасчСчет=40702810538000147270
ПолучательБанк1=ПАО Сбербанк
ПолучательБанк2=МОСКВА
ПолучательБИК=044525225
ПолучательКорсчет=30101810400000000225
КодНазПлатежа=
ВидОплаты=01
Код=0
НазначениеПлатежа=Косметическая продукция по дог.№2411 от 23.03.2023г.   С учётом НДС.
НазначениеПлатежа1=Косметическая продукция по дог.№2411 от 23.03.2023г.   С учётом НДС.
ПлательщикКПП=910201001
ПолучательКПП=772101001
Очередность=5
КонецДокумента
СекцияДокумент=Платежное поручение
Номер=896
Дата=16.08.2024
Сумма=2470.00
ПлательщикСчет=40702810444050026851
Плательщик=ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "АПТЕКА НА ИНСКОМ" (г.Новосибирск, филиал)
ПлательщикИНН=5409113098
Плательщик1=ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "АПТЕКА НА ИНСКОМ" (г.Новосибирск, филиал)
ПлательщикРасчСчет=40702810444050026851
ПлательщикБанк1=СИБИРСКИЙ БАНК ПАО СБЕРБАНК
ПлательщикБанк2=НОВОСИБИРСК
ПлательщикБИК=045004641
ПлательщикКорсчет=30101810500000000641
ПолучательСчет=40702810538000147270
ДатаПоступило=16.08.2024
Получатель=Общество с ограниченной ответственностью "ПЕРОЛАЙТ"
ПолучательИНН=7716859156
Получатель1=Общество с ограниченной ответственностью "ПЕРОЛАЙТ"
ПолучательРасчСчет=40702810538000147270
ПолучательБанк1=ПАО Сбербанк
ПолучательБанк2=МОСКВА
ПолучательБИК=044525225
ПолучательКорсчет=30101810400000000225
КодНазПлатежа=
ВидОплаты=01
НазначениеПлатежа=Оплата за товар по договору поставки, В том числе НДС 20 % - 411.67 рублей.
НазначениеПлатежа1=Оплата за товар по договору поставки, В том числе НДС 20 % - 411.67 рублей.
ПлательщикКПП=540901001
ПолучательКПП=772101001
Очередность=5
КонецДокумента
СекцияДокумент=Платежное поручение
Номер=174990
Дата=16.08.2024
Сумма=77042.23
ПлательщикСчет=40702810154400102828
Плательщик=АКЦИОНЕРНОЕ ОБЩЕСТВО "ФАРМПЕРСПЕКТИВА"
ПлательщикИНН=4028069779
Плательщик1=АКЦИОНЕРНОЕ ОБЩЕСТВО "ФАРМПЕРСПЕКТИВА"
ПлательщикРасчСчет=40702810154400102828
ПлательщикБанк1=ПОВОЛЖСКИЙ БАНК ПАО СБЕРБАНК
ПлательщикБанк2=САМАРА
ПлательщикБИК=043601607
ПлательщикКорсчет=30101810200000000607
ПолучательСчет=40702810538000147270
ДатаПоступило=16.08.2024
Получатель=Общество с ограниченной ответственностью "ПЕРОЛАЙТ"
ПолучательИНН=7716859156
Получатель1=Общество с ограниченной ответственностью "ПЕРОЛАЙТ"
ПолучательРасчСчет=40702810538000147270
ПолучательБанк1=ПАО Сбербанк
ПолучательБанк2=МОСКВА
ПолучательБИК=044525225
ПолучательКорсчет=30101810400000000225
КодНазПлатежа=
ВидОплаты=01
НазначениеПлатежа=За косметическую продукцию по сч/ф N6729 от 15.04.2024, N6759 от 22.04.2024, N6769 от 25.04.2024, N6804 от 02.05.2024, N6830 от 06.05.2024, N6851 от 13.05.2024 в т.ч. НДС 20% - 12840,38р.
НазначениеПлатежа1=За косметическую продукцию по сч/ф N6729 от 15.04.2024, N6759 от 22.04.2024, N6769 от 25.04.2024, N6804 от 02.05.2024, N6830 от 06.05.2024, N6851 от 13.05.2024 в т.ч. НДС 20% - 12840,38р.
ПлательщикКПП=402801001
ПолучательКПП=772101001
Очередность=5
КонецДокумента
КонецФайла
`

export default mytext